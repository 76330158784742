* {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
}
#root {
    width: 100vw;
    height: 100vh;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
::-webkit-scrollbar-thumb {
    background: #84ceff;
    border-radius: 13px;
    width: 4px;
    height: 4px;
}
::-webkit-scrollbar-track {
    background: #f6f6f6;
    width: 4px;
    height: 4px;
}

.dot-spin {
    color: #007bff;
    font-size: 7px;
    margin: 20px auto 40px auto;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    animation: load4 1.4s infinite linear;
    transform: translateZ(0);
}
@keyframes load4 {
    0%,
    100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}

.travelFlash::before {
    content: '""';
    height: 4px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    transform-origin: "left";
    z-index: 100;
    background: #37f4ff;
    border-radius: 13px;
    animation: travelFlashSpeed 2.8s linear infinite;
}

@keyframes travelFlashSpeed {
    0% {
        opacity: 1;
    }
    25%,
    100% {
        opacity: 0.5;
    }
    75% {
        opacity: 0;
    }
}
.bounce1 {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    animation: bounce 1s infinite;
    background: #000;
}

.bounce1:nth-child(2) {
    animation-delay: 150ms;
}
.bounce1:nth-child(3) {
    animation-delay: 300ms;
}

@keyframes bounce {
    0%,
    100% {
        transform: translate(0px, 0rem) scale(1.25);
    }
    20% {
        transform: translate(0px, -1.2rem) scale(0.9);
    }
}

.hotBlick {
    animation: blink 1.5s linear infinite;
}
@keyframes blink {
    0%,
    100% {
        border-color: #55555510;
    }
    50% {
        border-color: #555555ff;
        transform: scale(1.1);
    }
}

.react-pdf__Page,
.react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.react-pdf__Document {
    padding: 10px 0px;
}
.react-pdf__Page__canvas {
    /* width: 90% !important;
    height: 90% !important; */
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

* {
    transition: ease-in-out 200ms !important;
}

.tr_hover:hover {
    box-shadow: 0px 0px 2px 0px #73b7fff5;
    border-radius: 8px 0px;
    animation: effect 1.5s infinite linear;
    * {
        transform: scale(1.04);
        color: #007bff;
    }
}

@keyframes effect {
    0%,
    50%,
    100% {
        box-shadow: 0px 0px 5px 0px #73b7fff5;
    }
    30% {
        box-shadow: 0px 0px 2px 0px #007bff;
    }
}

.load-wave::before {
    content: "";
    position: absolute;
    bottom: -1px;
    left: -1px;
    width: 0.9rem;
    height: 0.9rem;
    border-radius: 50%;
    background: #007bff;
    animation: spin infinite 12s linear;
    opacity: 0;
}

@keyframes spin {
    70% {
        width: 0.98rem;
        height: 0.98rem;
        opacity: 1;
    }
    to {
        transform: rotate3d(5, 0, 5, 3600deg);
        opacity: 0;
    }
}
